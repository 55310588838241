import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import CommonParagraph from "../component/Blog/CommonParagraph";
import Heading from "../component/Blog/Heading";
import BlogLink from "../component/Blog/BlogLink";
import { blogList } from "../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/10-compelling-reasons-to-consider-moving-to-pacific-beach"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By {blogData[0].author}
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_Considering a Move to Pacific Beach_ 10 Reasons You'd Love Living There.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                A slice of paradise, Pacific Beach (commonly called PB by locals) is a stunning
                coastal town in San Diego, California. Its unique blend of relaxed beach vibes,
                heart-pounding outdoor adventures, and vibrant nightlife makes it the perfect
                location for families, working professionals, and retirees.
              </CommonParagraph>
              <CommonParagraph>
                If you're considering moving to Pacific Beach, here are ten reasons you would love
                living there!
              </CommonParagraph>
              <Heading type="h2">1. Seemingly Endless Sunshine</Heading>
              <CommonParagraph>
                One of the biggest reasons people from all over the country fall in love with
                Pacific Beach is the sunshine, which is practically year-round. With more than 250
                sunny days on average each year, you'll have plenty of opportunities to bask in the
                warm California glow and embrace all the outdoor activities that make PB so
                irresistible.
              </CommonParagraph>
              <Heading type="h2">2. Picturesque Beaches</Heading>
              <CommonParagraph>
                Who doesn't love miles of coastline made of soft, golden sand?{" "}
                <BlogLink
                  url="https://selbysellssd.com/buy-house-san-diego/"
                  text="Buying a home"
                />{" "}
                in this paradise means easy access to beachside bliss. Enjoy time out on the water,
                surfing or swimming, or sunbathe to your heart's content.
              </CommonParagraph>
              <Heading type="h2">3. Convenient Location</Heading>
              <CommonParagraph>
                Pacific Beach is the perfect balance of coastal serenity and urban convenience
                unlike other coastal areas. With easy access to major highways and abundant public
                transportation options, commuting to work, exploring nearby attractions, or heading
                out for a weekend getaway is a breeze.
              </CommonParagraph>
              <Heading type="h2">4. Outdoor Activities</Heading>
              <CommonParagraph>
                Pacific Beach has so much to offer lovers of the outdoors! Paddleboard in the serene
                waters of Mission Bay, hike Mount Soledad for breathtaking panoramic views, or
                admire the lush greenery of Kate Sessions Park during a stroll. No matter how you
                like to spend time outside, a scenic space awaits you!
              </CommonParagraph>
              <Heading type="h2">5. Exciting Nightlife</Heading>
              <CommonParagraph>
                Of course, the sun has to set at some point, and when it does, the city comes alive
                in a new way. From rooftop parties to cozy dive bars and live music, there's no
                shortage of venues that offer a memorable night out with friends.
              </CommonParagraph>
              <Heading type="h2">6. Diverse Culinary Scene</Heading>
              <CommonParagraph>
                Are you craving something specific? Chances are you'll find it in Pacific Beach!
                Ripe with beach bars, gourmet restaurants, and casual dining establishments, this
                Southern California city caters to every palate.
              </CommonParagraph>
              <Heading type="h2">7. Family-Friendly Atmosphere</Heading>
              <CommonParagraph>
                With top-rated schools and safe neighborhoods, this city isn't just for the working
                professional or retiree; it's a welcoming environment for growing families. Pacific
                Beach is a popular destination if you’re looking for a place to raise children.
              </CommonParagraph>
              <Heading type="h2">8. Booming Real Estate Market</Heading>
              <CommonParagraph>
                With a healthy mix of townhouses, condos, and single-family homes,{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/pacific-beach-homes-for-sale"
                  text="finding the perfect property"
                />{" "}
                for your lifestyle is easy. Plus, because of Pacific Beach's location, no matter
                where you end up, you're never far from the ocean!
              </CommonParagraph>
              <Heading type="h2">9. Community</Heading>
              <CommonParagraph>
                Many people love the strong sense of community in this beautiful city. Whether
                attending neighborhood events and volunteer opportunities or exploring local
                farmers' markets and the beach, residents look out for each other. You'll soon feel
                like part of the tight-knit PB family.
              </CommonParagraph>
              <Heading type="h2">10. Arts & Culture</Heading>
              <CommonParagraph>
                A beach-centric lifestyle isn't the only thing PB is known for; it also boasts a
                vibrant arts and cultural scene. From live theater performances and local galleries
                to outdoor festivals and concerts, there's always something exciting happening to
                inspire your senses and creativity.
              </CommonParagraph>
              <Heading type="h2">Make Pacific Beach Home With Help From The Selby Team</Heading>

              <CommonParagraph>
                Ready to love the city you live in? Then, make PB your home with help from The Selby
                Team. We streamline the entire process, helping you focus on selecting the property
                that best suits you and your new Pacific Beach lifestyle.{" "}
                <ContactSlideoutLink text="Reach out today" />!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
